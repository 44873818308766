<template>
  <div :class="classes">
    <div class="popup-header">
      <button @click="handleClose" class="close">
        <img src="@/assets/map/ic_close.svg" alt="" />
      </button>
    </div>
    <div class="popup-content">
      <h1>{{ data.station.cpo_name.toUpperCase() }}</h1>
      <h2>
        {{ data.station.street_number ? data.station.street_number + "," : "" }}
        {{ data.station.street_name }}, {{ data.station.town }},
        {{ data.station.zip_code }}
      </h2>
      <p>
        <b>{{ data.emi3 }}</b> - Puissance : {{ data.power }} kW
      </p>
      <hr />
      <section>
        <p>Connecteurs disponibles :</p>
        <div class="connecteurs">
          <span
            v-for="plug in data.connectors"
            v-bind:key="plug.connector_id"
            class="plug-format"
            v-html="plug.standard"
          ></span>
        </div>
      </section>
      <hr />
      <section>
        <button>S'abonner au réseau</button>
      </section>
      <hr />
      <section class="paiement-section">
        <a
          target="_blank"
          :href="'https://pay.chargedrive.com/' + data.emi3.split('*').join()"
          class="paiement"
        >
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-zap"
            >
              <polygon
                points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"
              ></polygon>
            </svg>
          </span>
          Paiement immédiat</a
        >
      </section>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: null
    },
    classes: {
      type: String,
      default: "popup"
    }
  },
  mounted() {
    this.data.connectors.forEach(connector => {
      switch (connector.standard) {
        case "SCHUKO":
          connector.standard = "&#xe80d;";
          break;
        case "DOMESTIC_A":
          connector.standard = "&#xe80d;";
          break;
        case "DOMESTIC_B":
          connector.standard = "&#xe80d;";
          break;
        case "DOMESTIC_C":
          connector.standard = "&#xe80d;";
          break;
        case "DOMESTIC_D":
          connector.standard = "&#xe80d;";
          break;
        case "DOMESTIC_E":
          connector.standard = "&#xe80d;";
          break;
        case "DOMESTIC_F":
          connector.standard = "&#xe80d;";
          break;
        case "DOMESTIC_G":
          connector.standard = "&#xe80d;";
          break;
        case "DOMESTIC_H":
          connector.standard = "&#xe80d;";
          break;
        case "DOMESTIC_I":
          connector.standard = "&#xe80d;";
          break;
        case "DOMESTIC_J":
          connector.standard = "&#xe80d;";
          break;
        case "DOMESTIC_K":
          connector.standard = "&#xe80d;";
          break;
        case "DOMESTIC_L":
          connector.standard = "&#xe80d;";
          break;
        case "TYPE_1":
          connector.standard = "&#xe80e;";
          break;
        case "IEC_62196_T1":
          connector.standard = "&#xe80e;";
          break;
        case "IEC_62196_T2":
          connector.standard = "&#xe80f;";
          break;
        case "TYPE_2":
          connector.standard = "&#xe80f;";
          break;
        case "TYPE_2_ATCH":
          connector.standard = "&#xe80f;";
          break;
        case "TYPE_3":
          connector.standard = "&#xe810;";
          break;
        case "IEC_62196_T3A":
          connector.standard = "&#xe810;";
          break;
        case "IEC_62196_T3C":
          connector.standard = "&#xe810;";
          break;
        case "CHADEMO":
          connector.standard = "&#xe803;";
          break;
        case "CCS":
          connector.standard = "&#xe80c;";
          break;
        case "IEC_62196_T2_COMBO":
          connector.standard = "&#xe80c;";
          break;
        case "IEC_62196_T1_COMBO":
          connector.standard = "&#xe80c;";
          break;
        default:
          break;
      }
    });
    //(this.data.connectors)
  },
  methods: {
    handleClose() {
      this.$emit("close");
    }
  }
};
</script>

<style lang="scss">
.popup {
  height: 98vh;
  background-color: white;
  border-radius: 25px;
  position: absolute;
  z-index: 9999;
  width: 100vw;
  margin-top: 2vh;
  overflow: scroll !important;
  transform: translateY(100vh);

  &.show {
    animation: slideUp 1s forwards;
  }
  .popup-header {
    text-align: right;
    padding: 15px;
    button {
      padding: 10px;
      border-radius: 100%;
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      background: #eee;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .popup-content {
    padding: 20px;
    h2 {
      text-transform: uppercase;
      font-weight: lighter;
      margin-bottom: 20px;
      font-size: 14px;
    }
    hr {
      border: 1px solid #eee;
    }
    section {
      &.paiement-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      margin: 5vh 0;
      button {
        padding: 10px 20px;
        border-radius: 100px;
        color: white;
        background-color: #212121;
      }
      a {
        padding: 10px 20px;
        border-radius: 100px;
        color: white;
        background-color: #212121;
        text-decoration: none;
        &.paiement {
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 25px;
          span {
            display: inline-flex;
            justify-content: center;
            margin-right: 10px;
            align-items: center;
            background: #f3e050;
            color: black;
            width: 20px;
            height: 20px;
            border-radius: 100%;
          }
          background-color: #212121;
        }
      }
      .connecteurs {
        display: flex;
        flex-wrap: wrap;
        font-size: 30px;
        font-family: "SCHUKO";
        img {
          width: 50px;
          height: 50px;
        }
      }
    }
  }
}
</style>
