<template>
  <div class="qrCodeWrapper">
    <button
      class="flash"
      v-if="torchIsSupported"
      @click="torcheActive = !torcheActive"
    >
      <svg
        v-if="!torcheActive"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-zap"
      >
        <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2"></polygon>
      </svg>
      <svg
        v-else
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-zap-off"
      >
        <polyline points="12.41 6.75 13 2 10.57 4.92"></polyline>
        <polyline points="18.57 12.91 21 10 15.66 10"></polyline>
        <polyline points="8 8 3 14 12 14 11 22 16 16"></polyline>
        <line x1="1" y1="1" x2="23" y2="23"></line>
      </svg>
    </button>
    <PopupScan
      @close="handleClose"
      v-if="showPopup"
      :classes="showPopup ? 'popup show' : 'popup'"
      :data="data"
    />
    <div class="square-scan">
      <img src="@/assets/scan.svg" />
    </div>
    <qrcode-stream
      :camera="camera"
      @init="onInit"
      :torch="torcheActive"
      @decode="handleDecode"
    ></qrcode-stream>
  </div>
</template>
<script>
import PopupScan from "@/components/map/pin/PopupScan.vue";
export default {
  components: {
    PopupScan
  },
  data() {
    return {
      torcheActive: false,
      torchIsSupported: false,
      showPopup: false,
      result: null,
      data: {},
      camera: "auto"
    };
  },
  methods: {
    async onInit(promise) {
      try {
        const { capabilities } = await promise;
        this.torchIsSupported = capabilities.torch;
      } catch (error) {
        //.error(error)
        alert(error);
      }
    },
    async handleDecode(e) {
      try {
        throw "dodne";
      } catch (f) {
        let result = e.split("*");

        let cp, connector;
        switch (result.length) {
          case 4:
            this.$router.push({
              name: "ScanResult",
              params: {
                emi3: result.join("*"),
                fromScan: true
              }
            });
            break;
          case 5:
            cp = result[result.length - 1];
            result.pop();
            this.$router.push({
              name: "ScanResult",
              params: {
                emi3: result.join("*"),
                cp_id: cp,
                fromScan: true
              }
            });
            break;
          case 6:
            cp = result[result.length - 2];
            connector = result[result.length - 1];
            result.pop();
            result.pop();
            this.$router.push({
              name: "ScanResult",
              params: {
                emi3: result.join("*"),
                cp_id: cp,
                connector_id: connector,
                fromScan: true
              }
            });
        }
      }
    },
    handleClose() {
      this.camera = "auto";
      this.showPopup = false;
    }
  }
};
</script>
<style lang="scss">
.qrCodeWrapper {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
.v-application--wrap {
  height: 100vh !important;
  width: 100vw !important;
  overflow: hidden;
}
.toggle-torche {
  position: absolute;
  z-index: 15;
}
.square-scan {
  position: absolute;
  z-index: 15;
  top: 50%;
  width: 60vw;
  height: 60vw;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  @media (min-width: 1280px) {
    height: 30vw;
    width: 30vw;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
button.flash {
  position: absolute;
  z-index: 20;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  border-radius: 3px;
  top: 50vh;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  left: calc(50% - 20px);
}

// * {
//   overflow: hidden !important;
// }
video::-webkit-media-controls {
  display: none !important;
}

@keyframes slideUp {
  from {
    transform: translateY(100vh);
  }
  to {
    transform: translateY(2vh);
  }
}
</style>
